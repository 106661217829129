@import "variables.scss";

.users-overview {
    .user {
        color: $primary-text-color;
        user-select: none;
        transition: all 0.2s;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        background-color: $primary-element-color;
        margin-bottom: 4px;
        border-right: 2px solid transparent;
        cursor: pointer;

        &:hover {
            cursor: pointer;
            border-color: $primary-color;
        }

        .name {
            flex-grow: 1;
            padding-left: 16px;
        }

        .button {
            transition: all 0.2s;
            color: $primary-icon-color;
            font-size: 16px;
            border-left: 4px solid $primary-background-color;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 16px;
            .icon {
                font-size: 24px;
            }
            &:hover {
                color: white;
                background-color: $primary-color;
            }
        }

        &.selected {
            border-color: $primary-color;
        }
    }
}
