.users-rootview {
    background-color: $scondary-background-color;

    display: grid;
    grid-template-columns: 64px min-content minmax(20%, 100%);
    grid-template-areas: "navigation sidebar map";

    height: 100%;
    width: 100%;

    .sidebar {
        grid-area: sidebar;
        width: 500px;
        background-color: $primary-background-color;
    }

    .user-edit-container {
        grid-area: map;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
