.map-edit-view {
        min-width: 500px;
    .cache-status-container {
        padding: 8px;
        width: 25%;
        margin-right: 8px;
        cursor: pointer;
        transition: all 0.2s;
        text-align: center;
        background: $primary-color;

        span {
            color: white;
        }
        &:hover {
            background: lighten( $primary-color, 5%);
        }

        &.uncached {
            background: $primary-error-color;
        }
    }
    .container {
        padding: 24px;

        .datasets {
            padding: 8px;
            padding-top: 16px;

            .dataset {
                display: flex;
                align-items: center;
                padding-left: 16px;
                background-color: $scondary-background-color;
                color: white;
                margin-bottom: 4px;

                .name {
                    flex-grow: 1;
                }

                .icon {
                    margin-right: 8px;
                    color: $primary-icon-color;
                    cursor: pointer;
                    transition: 0.2s all;
                    &:hover {
                        color: $primary-color;
                    }
                }

                .cached-container{
                    display: flex;
                    background-color: $primary-color;
                    padding: 4px;
                    margin: 12px;
                    
                    span{
                        color: white;
                        margin: 4px;
                    }
                }
            }

            .add-button {
                display: flex;
                align-items: center;
                padding: 16px;
                background-color: $scondary-background-color;
                color: $primary-icon-color;
                cursor: pointer;
                transition: 0.2s all;
                &:hover {
                    background-color: $primary-color;
                    color: white;
                    span {
                        color: white;
                    }
                }

                .icon {
                    margin-right: 8px;
                }
            }
        }

        .section {
            .map-id-container {
                padding: 16px;
                margin: 8px;
                margin-bottom: 16px;
                text-align: center;
                background: $scondary-background-color;
            }

            .text-container {
                padding: 8px 8px;
            }
        }

        .actions{
            margin-top: 16px;
        }

        .back-btn {
            margin-right: 16px;
        }
    }
}

.dataset-dialog {
    .MuiDialog-paper {
        background-color: $primary-background-color;
    }

    .title {
        background-color: $primary-color;

        h6 {
            color: white;
        }
    }

    .container {
        padding: 16px 24px;
    }
    .add-btn {
        margin-top: 16px;
    }
}
