@import "variables.scss";

.navigation-menu {
    width: 64px;
    grid-area: navigation;
    background-color: $secondary-color;

    .header {
        width: 64px;
        height: 64px;
        background-color: #013c5c;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
            background-color: lighten(#013c5c, 5%);
        }

        .icon {
            color: white;
        }
    }

    .container {
        display: flex;
        align-items: center;
        flex-direction: column;
        .button {
            width: 64px;
            height: 64px;
            border-left: 2px solid $primary-color;
            background-color: $secondary-color;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.2s all;
            cursor: pointer;

            &.active {
                background-color: $primary-color;
                border-left: 2px solid white;

                .icon {
                    color: white;
                }
            }

            &:hover {
                background-color: $primary-color;
                .icon {
                    color: white;
                }
            }

            .icon {
                color: lightgray;
            }

            .flex-grow {
                flex-grow: 1;
            }
        }

        .logout-button {
            width: 64px;
            height: 64px;
            position: absolute;
            bottom: 0;
            background-color: $secondary-color;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.2s all;
            cursor: pointer;

            .icon {
                color: lightgray;
            }

            &:hover {
                background-color: $primary-color;
                .icon {
                    color: white;
                }
            }
        }
    }
}
