.sidebar-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    .toolbar {
        background-color: white;
        .title {
            flex-grow: 1;
            color: $primary-text-color;
            background-color: white;

          
        }
        img{
            height: 30px;
        }
    }

    .header {
        padding-left: 16px;
        padding-right: 8px;
        align-items: center;
        background-color: white;
        display: flex;
        min-height: 52px;
        border-top: 1px solid lightgray;
       

        h6 {
            color: $primary-text-color;
            font-weight: bold;
            flex-grow: 1;
        }

        .button {
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 52px;
            width: 52px;
            transition: 0.2s all;
            cursor: pointer;

            &:hover {
                background-color: #4d5767;
            }
        }
    }

    .content {
        padding: 8px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        height: 100%;

        .search {
            padding: 16px;
        }

        .item-toolbar {
            padding: 0px 16px;
            display: flex;
            margin-right: 62px;

            .button {
                height: 36px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                color: $primary-text-color;
                border-bottom: 1px solid $tertiary-background-color;
                cursor: pointer;
                transition: all 0.2s;

                &:hover {
                    border-bottom: 1px solid $primary-color;
                    color: $primary-color;
                }

                &.active {
                    border-bottom: 1px solid $primary-color;
                    color: $primary-color;
                }

                &.schema {
                    width: 96px;
                    margin-right: 4px;
                }

                &.geometry {
                    width: 56px;

                    margin-right: 4px;
                }
                &.name {
                    flex-grow: 1;
                }
                &.date {
                    width: 132px;
                    margin-left: 4px;
                }
            }
        }

        .item-container {
            margin: 0px 16px;

            overflow: auto;
        }
    }
}
