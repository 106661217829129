@import "variables.scss";

.maps-overview {
    min-width: 500px;
    .map {
       
        color: $primary-text-color;
        user-select: none;
        transition: all 0.2s;
        display: flex;
        margin-bottom: 8px;
        border-right: 1px solid transparent;

        &.selected {
            border-color: $primary-color;
        }

        &:hover {
            cursor: pointer;
            border-color: $primary-color;
           
        }

        .container {
            display: flex;
            width: 100%;

            .row-container {
                flex-grow: 1;
                background-color: $primary-element-color;
                .row-1 {
                    display: flex;
                    align-items: center;

                    &.cached {
                        border-left: $primary-color 2px solid;
                    }

                    &.uncached {
                        border-left: darkred 2px solid;
                    }

                    .button {
                        color: #797979;
                        display: flex;
                        border-left: 2px solid $primary-background-color;
                        align-items: center;
                        padding: 16px;

                        .icon {
                            font-size: 24px;
                        }

                        &:hover {
                            color: white;
                            background-color: #3e4550;
                        }
                    }

                    .icon {
                        font-size: 16px;
                        margin-right: 8px;
                    }
                }

                .row-2 {
                    height: 50px;
                    padding-left: 16px;
                    display: flex;
                    align-items: center;
                    background-color: #292e38;

                    .icon {
                        font-size: 16px;
                        margin-right: 8px;
                    }
                }
            }

            .name {
                background-color: $primary-element-color;
                text-transform: capitalize;
                display: flex;
                align-items: center;
                padding-left: 16px;
                flex-grow: 1;
                height: 56px;
                font-size: 16px;
            }

            .created {
                font-size: 12px;
                margin-right: 24px;

                &.grow {
                    flex-grow: 1;
                }
            }

            .row-count {
                margin-right: 24px;
                font-size: 12px;
                font-size: 10px;
            }
        }
    }
}

.create-map-modal {
    .upload-box {
        width: 100%;
        height: 250px;
        border: 1px dotted white;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: transparent;

        .text {
            color: #cacaca;
            text-align: center;
        }

        .icon {
            font-size: 36px;
        }
    }
}
