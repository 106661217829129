.login{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primary-background-color;
    
    .container{
        border: gray 1px solid;
        padding: 16px;
        width: 25%;
    }

   
    .login-container{
        margin-top: 30px;
    }

    .primary-btn{
        margin-top: 15px;
    }
}