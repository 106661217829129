.datasets-rootview {
    background-color: $primary-background-color;

    display: grid;
    grid-template-columns: 64px min-content minmax(20%, 100%);
    grid-template-areas: "navigation sidebar map";

    height: 100%;
    width: 100%;

    .sidebar {
        grid-area: sidebar;
        min-width: 500px;
        background-color: $primary-background-color;
        display: flex;
        overflow: auto;
        transition: all 0.2s;

        &.closed {
            min-width: 0px;
            width: 0;
        }
    }

    .map {
        grid-area: map;

        position: relative;
    }

    .map-data-switch {
        display: flex;
        align-items: center;
        color: $primary-text-color;
        padding: 8px;
        padding-left: 16px;
        padding-right: 16px;
        background-color: $primary-background-color;
        position: absolute;
        bottom: 16px;
        right: 16px;
    }
}
