.edit-style-view {
    .content {
        display: grid;
        grid-template-columns: min-content min-content;
        grid-template-areas: "layers style";
        padding: 0;

        .layers-container {
            grid-area: layers;
            padding: 16px;
            min-width: 300px;
            background-color: $primary-background-color;

            .header {
                padding: 8px;
                padding-left: 16px;
                background-color: #222e46;
                display: flex;
                align-items: center;
            }

            .list-actions {
                display: flex;

                margin-top: 8px;

                .action {
                    font-size: 16px;
                    padding: 12px;
                    transition: all 0.2s;
                    background-color: $scondary-background-color;
                    color: $primary-text-color;
                    cursor: pointer;

                    &:hover {
                        background-color: $primary-color;
                        color: white;
                    }
                }
            }

            .layers {
                margin-top: 8px;
                width: 100%;

                .layer {
                    color: $primary-text-color;
                    cursor: pointer;
                    transition: 0.2s all;
                    display: flex;
                    align-items: center;
                    margin-bottom: 4px;
                    background-color: $scondary-background-color;
                    border-right: 2px solid transparent;

                    &:hover {
                        border-right: 2px solid $primary-color;
                    }

                    &.selected {
                        border-right: 2px solid $primary-color;
                    }

                    &.drag-highlight {
                        border-top: $primary-color 2px solid;
                        transition: none;
                    }

                    .type {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0px 16px;
                        height: 56px;
                        .fill {
                            height: 16px;
                            width: 16px;
                            border: $primary-background-color 2px solid;
                        }

                        .circle {
                            height: 16px;
                            width: 16px;
                            border-radius: 50%;
                            border: $primary-background-color 2px solid;
                        }

                        .line {
                            width: 16px;
                            height: 4px;
                            border: $primary-background-color 2px solid;
                        }

                        .symbol {
                            font-weight: bold;
                            font-size: 20px;
                        }
                    }

                    .name {
                        flex-grow: 1;
                        font-size: 14px;
                        display: flex;
                        align-items: center;
                        pointer-events: none;
                    }

                    .actions {
                        display: flex;
                        margin-right: 8px;

                        .action {
                            font-size: 16px;
                            padding: 12px;
                            transition: all 0.2s;

                            &:hover {
                                background-color: $primary-color;
                                color: white;
                            }
                        }
                    }
                }

                .add-button {
                    color: $primary-text-color;
                    cursor: pointer;
                    transition: 0.2s all;
                    display: flex;
                    align-items: center;
                    margin-bottom: 4px;
                    padding: 16px;
                    background-color: $scondary-background-color;

                    .icon {
                        padding-right: 8px;
                    }

                    &:hover {
                        background-color: $primary-color;
                    }
                }
            }
        }

        .style-container {
            grid-area: style;
            overflow: auto;
            background-color: $primary-background-color;
            border-left: $scondary-background-color solid 1px;
            display: flex;
        }
    }

    .style {
        display: flex;
        flex-direction: column;

        .tabs {
        }

        .data-tab {
            width: 400px;
        }

        .style-tab {
            display: flex;
            overflow: auto;
            width: 400px;

            .style-properties {
                margin-right: 4px;
                padding-right: 4px;
                overflow: auto;
            }

            .style-property {
                display: flex;
                overflow: auto;
                flex-direction: column;
                width: 100%;

                .values {
                    display: flex;
                    overflow: auto;
                    flex-direction: column;
                }
            }

            .property-container {
                width: 300px;
                display: flex;
                overflow: auto;

                .property-title {
                    padding: 8px 8px;

                    background-color: $scondary-background-color;
                    display: flex;
                    align-items: center;
                    margin-bottom: 8px;

                    .actions {
                        display: flex;
                        svg {
                            color: $primary-icon-color;
                        }
                    }

                    .property-title-type {
                        color: $secondary-text-color;
                    }

                    .property-title-name {
                        flex-grow: 1;
                    }
                }
            }
        }

        .style-content {
            display: flex;
            flex-direction: column;
            overflow: auto;
            padding: 16px 8px;

            .section-container {
                background-color: $scondary-background-color;
                padding: 16px;
                margin-bottom: 8px;
            }

            .title {
                color: $primary-text-color;
            }

            .select {
                .menu-item-name {
                    flex-grow: 1;
                    font-size: 14px;
                }
            }

            .section:not(:last-child) {
                margin-bottom: 8px;
            }

            .header {
                padding: 8px;
                padding-left: 16px;
                background-color: #222e46;
                display: flex;
                align-items: center;
            }
            .slider-container {
                padding: 0px 16px;
                .slider-footer {
                    span {
                        font-size: 10px;
                        color: $primary-text-color;

                        &:not(:first-child) {
                            float: right;
                        }
                    }
                }
            }
            .rc-slider {
                margin-top: 15px;

                .rc-slider-rail {
                    background-color: $primary-background-color;
                }

                .rc-slider-handle {
                    background-color: white;
                    border-color: white;
                    box-shadow: 0 0 5px $primary-color;

                    &:hover {
                        border-color: $primary-color;
                    }
                }

                .rc-slider-track {
                    background-color: $primary-color;
                }
            }
        }

      
    }
}

.menu-item-geometry-type {
    text-transform: lowercase;
    padding: 4px 8px;
    background-color: $primary-color;
    color: white;
    font-size: 14px;
}
.menu-item-name {
    flex-grow: 1;
    font-size: 18px;
}

.types {
    display: flex;

    align-items: center;

    .grow {
        flex-grow: 1;
        padding: 16px;
    }

    .type {
        background-color: $scondary-background-color;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        cursor: pointer;
        transition: all 0.2s;
        margin-right: 4px;

        &.active {
            background-color: $primary-color;

            svg {
                color: white;
                width: 32px;
                height: 32px;
            }
        }

        svg {
            color: #595e69;
            width: 32px;
            height: 32px;
        }

        &:hover {
            background-color: $primary-color;

            svg {
                color: white;
            }
        }
    }
}

.graduate-style-modal {
    .next-button {
        margin-top: 16px;
    }

    .steps-container {
        padding: 8px 16px;
    }

    .actions {
        margin-top: 8px;
        margin-bottom: 8px;

        padding: 8px;

        svg{
            color: $primary-icon-color;
        }
    }

    .scroll-container {
        max-height: 350px;
        overflow: auto;
    }

    .step-container {
        padding: 8px;
        display: flex;
        align-items: center;
        background-color: $scondary-background-color;
        margin-bottom: 4px;

        .step-value {
            flex-grow: 1;
            color: $primary-text-color;
        }

        .step-color {
            width: 32px;
            height: 32px;
            border: 1px solid $tertiary-background-color;
        }
    }
}

.menu-item-flex {
    display: flex;

    .name {
        flex-grow: 1;
    }
}

.property-button {
    padding: 8px;
    background-color: $scondary-background-color;
    display: flex;
    flex-direction: column;
    width: 72px;
    height: 48px;
    margin-bottom: 8px;
    justify-content: center;
    border-right: 2px solid transparent;
    transition: all 0.2s;
    cursor: pointer;

    &:hover {
        border-right: 2px solid $primary-color;
    }

    &.selected {
        border-right: 2px solid $primary-color;
    }

    .property-title {
        font-size: 12px;
        margin-bottom: 8px;
        font-weight: bold;
        color: $primary-text-color;
    }

    .property-value {
        font-size: 10px;
        color: $secondary-text-color;
    }

    .color-value {
        width: 16px;
        height: 16px;
    }
}

.color-ramp-name {
    flex-grow: 1;
    padding-right: 16px;
}

.color-ramp-gradient {
    height: 32px;
    width: 64px;
    padding-right: 16px;
    border: 1px solid $tertiary-background-color;
}
