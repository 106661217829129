

.property {
    margin-bottom: 8px;
   

    .toolbar {
        height: 16px;
        background-color: $tertiary-background-color;

        .actions {
            display: flex;

            .action {
                height: 16px;
                width: 16px;
                color: white;
                cursor: pointer;
                padding-left: 4px;
                padding-right: 4px;

                &:hover {
                    background-color: $primary-background-color;
                }

                svg {
                    height: 16px;
                    width: 16px;
                }
            }
        }
    }

    .property-content {
        background-color: $scondary-background-color;
        display: flex;
        height: 64px;
        &.center {
            align-items: center;
            justify-content: center;
        }

        .name {
            color: $primary-text-color;
            padding: 16px;
            text-transform: capitalize;
            display: flex;
            align-items: center;
            flex-grow: 1;
            border-right: 4px solid $primary-background-color;
        }

        .textfield{
            padding: 16px;
            flex-grow: 1;
            border-right: 4px solid $primary-background-color;
        }

        .mini-icon{
            color:$primary-icon-color;
            margin: 16px;
        }

        .select {
            width: 150px;
        }

        .text-input {
            flex-grow: 1;

            input {
                height: 19px;
            }
        }

        .add-btn {
            height: 56px;
            width: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:hover {
                background-color: $primary-color;
               
            svg {
                color: white;
            }
            }

            svg {
                color: $primary-icon-color;
            }
        }

        .delete-btn {
            width: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            svg {
                color: #404040;
                width: 20px;
                height: 20px;
            }

            &:hover {
                background-color: #232323;

                svg {
                    color: white;
                }
            }
        }

        .color-container {
         
            width: 64px;
            height: 64px;
            cursor: pointer;
            transition: filter 0.2s;
        
            &:hover {
                filter: brightness(75%);
            }
        }

        .input-container {
          

            height: 64px;
            line-height: 64px;

            .number {
                color: $primary-text-color;
                width: 64px;

                input {
                    text-align: center;
                }
            }
        }

        .property-select{
            height: 100%;

            .MuiInputBase-root{
                height: 100%;
            }
        }

        .text-container{
            width: 168px;
            line-height: 64px;
         

            .text {
                color: $primary-text-color;
                font-size: 14px;
                width: 100%;

                input {
                    text-align: center;
                    
                }
            }
        }

        .checkbox-container{
            width: 64px;
            display: flex;
            align-items: center;
        }

        .boolean-property{

        }
    }
}
