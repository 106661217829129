.user-edit-view{
   
    .header{
        padding: 16px;
        background-color: $primary-color;
        
        h6{
            color:white
        }
    }

    .container{
        padding: 16px;
        background-color: $primary-background-color;

        .section:not(:last-child){
            margin-bottom: 16px;
        }

        .section{         
            &.flex{
                display: flex;
                
                .button {
                    margin-right: 8px;
                }
            }

            .username-container{

            }

           
        }
    }

   
}