.file-upload {

    .multi-switch{
        margin-bottom: 8px;
    }

    .file-upload-box {
        width: 100%;
        height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: $primary-element-color;

        &.dragging {
            background-color: #1c1f24;
        }

        .text {
            color: $primary-text-color;
            text-align: center;
        }

        .icon {
            font-size: 36px;
            color: white;
        }

        .button {
            margin-top: 16px;
        }
    }
}
