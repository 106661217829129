@import "variables.scss";

.dataset-details {
    width: 500px;

    .cache-status-container {
        padding: 8px;
        width: 25%;
        margin-right: 8px;
        transition: all 0.2s;
        text-align: center;
        background-color: $primary-color;
        cursor: pointer;
        transition: all 0.2s;

        span {
            color: white;
        }

      

        &.cached {
            background-color: $primary-color;

            &:hover {
                background: lighten( $primary-color, 5%);
            }
        }

        &.uncached {
            background-color: $primary-error-color;

            &:hover {
                background: lighten( $primary-color, 5%);
            }
        }
    }

    .container {
        padding: 16px 16px;
        display: flex;
        flex-direction: column;
        overflow: auto;
    }

    .page {
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;
        overflow: auto;
    }

    .section {
        padding: 0px 8px;
        margin-bottom: 8px;

        &.scroll {
            display: flex;
            flex-direction: column;
            overflow: auto;
        }

        .flex {
            display: flex;
            justify-content: space-between;
        }

        .subtitle {
            padding-top: 8px;
        }

        .text-container {
            padding: 8px;
        }

        .generated-tiles {
            margin-top: 8px;
            font-size: 24px;
            padding: 10px;
            border: 1px dashed $primary-color;
            text-align: center;
            color: $primary-text-color;

            .generated-tiles-header {
                color: $primary-text-color;
                font-size: 12px;
            }
        }

        .zoom-levels-container {
            align-items: center;
            color: $primary-text-color;
            width: 100%;

            .value {
                font-size: 16px;
                padding: 16px;
                margin-top: 8px;
                margin-left: 8px;
                margin-right: 8px;
                background-color: $scondary-background-color;
                text-align: center;
            }
        }

        .slider-container {
            .slider-footer {
                span {
                    font-size: 10px;
                    color: $primary-text-color;

                    &:not(:first-child) {
                        float: right;
                    }
                }
            }
        }
    }

    .checkbox-header {
        color: lightgray;
        font-size: 12px;
        margin-bottom: 5px;
    }

    .checkbox-container {
        padding: 8px;
        padding-bottom: 0;
        display: flex;
        flex-direction: column;
        overflow: auto;

        .checkbox {
            background-color: $scondary-background-color;
            display: flex;
            align-items: center;
            padding-right: 16px;

            margin-bottom: 4px;
            .label {
                font-size: 14px;
                color: $primary-text-color;
                padding: 16px 0px;
            }
            .box {
                margin-right: 8px;
            }
            .seperator {
                background-color: $scondary-background-color;
                color: $primary-color;
                border-right: 2px solid $primary-background-color;
                border-left: 2px solid $primary-background-color;
                padding: 16px;
                margin-left: 16px;
                margin-right: 16px;
            }
            .flex {
                display: flex;
                align-items: center;
                flex-grow: 1;
                height: 100%;
                cursor: pointer;

             
            }
        }

      
    }
    .all-checkbox{
        display: flex;
        align-items: center;
        padding: 0px 8px;
    }
    .rc-slider {
        margin-top: 15px;
    
        .rc-slider-rail {
            background-color: $scondary-background-color;
        }
    
        .rc-slider-handle {
            background-color: white;
            border-color: white;
            box-shadow: 0 0 5px $primary-color;
    
            &:hover {
                border-color: $primary-color;
            }
        }
    
        .rc-slider-track {
            background-color: $primary-color;
        }
    }

    .back-btn {
        margin-right: 8px;
    }
}
