.atlas-modal {
    display: flex;
    justify-content: center;
    align-items: center;

    .container {
        background-color: $scondary-background-color;
        min-width: 300px;

        &:focus {
            outline: none;
        }

        .header {
            padding: 16px;
            background-color: $primary-color;
            text-align: center;
            color: white;
        }

        .page {
            padding: 16px;
            background-color: $primary-background-color;

            .section {
                margin-bottom: 16px;
            }

            .button-container {              
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}
