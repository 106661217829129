@import "variables.scss";

.datasets-overview {
    width: 700px;

    .dataset {
        color: $primary-text-color;
        user-select: none;
        transition: all 0.2s;
        display: flex;
        margin-bottom: 4px;
        border-right: 2px solid transparent;

        &.selected {
            border-color: $primary-color;
        }

        &:hover {
            cursor: pointer;
            border-color: $primary-color;
        }

        .container {
            display: flex;
            width: 100%;

            .row-container {
                flex-grow: 1;
                background-color: $primary-element-color;
                .row-1 {
                    display: flex;
                    align-items: center;

                    .geometry-icon {
                        display: flex;
                        padding: 16px;
                        justify-content: center;
                        align-items: center;
                        background-color: $scondary-background-color;
                        border-right: 4px solid $primary-background-color;
                    }

                    &.cached {
                        .geometry-icon {
                            color: $primary-color;
                        }
                    }

                    &.uncached {
                        .geometry-icon {
                            color: $primary-error-color;
                        }
                    }

                    .button {
                        color: #797979;
                        border-left: 4px solid $primary-background-color;
                        display: flex;
                        align-items: center;
                        padding: 16px;
                        transition: all 0.2s;

                        .icon {
                            font-size: 24px;
                        }

                        &:hover {
                            color: white;
                            background-color: $primary-color;
                        }
                    }
                }

                .row-2 {
                    height: 50px;
                    padding-left: 16px;
                    display: flex;
                    align-items: center;
                    background-color: #292e38;

                    .icon {
                        font-size: 16px;
                        margin-right: 8px;
                    }
                }
            }

            .name {
                background-color: $primary-element-color;
                display: flex;
                align-items: center;
                padding-left: 16px;
                flex-grow: 1;
                height: 56px;
                font-size: 14px;
                
            }

            .schema{
                padding: 0px 8px;
                height: 56px;  
                display: flex;
                align-items: center;
                justify-content: center;        
                font-size: 14px;
                border-right: 4px solid $primary-background-color;
                min-width: 80px;
                max-width: 80px;
                overflow: hidden;
                color: $primary-text-color;
               }

           .date{
            padding: 0px 16px;
            height: 56px;  
            display: flex;
            align-items: center;
            justify-items: center;        
            font-size: 14px;
            border-left: 4px solid $primary-background-color;
            min-width: 100px;
            max-width: 100px;
           }

            .created {
                font-size: 12px;
                margin-right: 24px;

                &.grow {
                    flex-grow: 1;
                }
            }

            .row-count {
                margin-right: 24px;
                font-size: 12px;
                font-size: 10px;
            }
        }

        
    }
}

.create-dataset-modal {
    .file-name {
        padding: 16px;
        border: 2px solid $primary-element-color;
        text-align: center;
        margin-bottom: 16px;
    }

    .file-error{
        padding: 16px;
        background-color: #db6a64;
        color: white;

        &:not(:first-child){
            border-top: 2px solid #f1f0f0;
        }

        .title{
            margin-bottom: 8px;
        }
        .text{
            font-size: 14px; 
            display: flex;
            align-items: center;

            .highlight{
                padding: 4px;
                margin: 0px 4px;
                background-color: white;
                color: #db6a64;
            }
        }
    }
}
