.group-edit-view{
   
    .header{
        padding: 16px;
        background-color: $primary-color;
        
        h6{
            color: white;
        }
    }

    .container{
        padding: 16px;
        background-color: $primary-background-color;
      

        .users{
            padding: 16px;

            .user{
                cursor: pointer;
                padding: 16px;
                color: white;
                border: 1px solid grey;

                &:hover{
                    border: 1px solid #008B8B;
                }
            }
        }

        .datasets{
            
            .dataset{
                display: flex;
                align-items: center;
                padding: 8px;
                padding-left: 16px;
                padding-right: 16px;
                color: $primary-text-color;
                background-color: $scondary-background-color;
                border-bottom: $primary-background-color  solid 1px;

                .name{
                    font-size: 16px;
                    flex-grow: 1;
                    width: 200px;
                    overflow: hidden;
                
                }

                .checkbox-container{
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    .radio-label{
                        font-size: 12px;
                    }
                }

              
            }
        }

        .list{
            background-color: $primary-background-color;
            min-height: 500px;
            max-height: 500px;
            overflow: auto;
        }

        .section{
            margin-bottom: 16px;

            &.flex{
                display: flex;
            }
        }
    }
}