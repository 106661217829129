.maps-rootview {
    background-color: $primary-background-color;

    display: grid;
    grid-template-columns: 64px min-content minmax(20%, 100%);
    grid-template-areas: "navigation sidebar map";

    height: 100%;
    width: 100%;


    .sidebar{
        grid-area: sidebar;
        background-color: $primary-background-color;
        display: flex;
        overflow: auto;
    }
    
    .map{
        grid-area: map;
    }
}

