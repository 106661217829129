@import "variables.scss";
@import "../../node_modules/react-redux-toastr/src/styles/index.scss";

@import "./modal.scss";
@import "./sidebar-container.scss";

@import "../views/login/loginView/_loginView.scss";

@import "../views/datasets/datasetsRootView/_datasetsRootView.scss";
@import "../views/datasets/datasetsListView/_datasetsListView.scss";
@import "../views/datasets/datasetEditView/_datasetEditView.scss";
@import "../views/datasets/tableView/_tableView.scss";

@import "../views/styleEditor/editStyleView/_editStyleView.scss";
@import "../views/styleEditor/editStyleView/components/styleProperties/_styleProperties.scss";

@import "../views/maps/mapsRootView/_mapsRootView.scss";
@import "../views/maps/mapsListView/_mapsListView.scss";
@import "../views/maps/mapEditView/_mapEditView.scss";

@import "../views/groups/groupsRootView/_groupsRootView.scss";
@import "../views/groups/groupsListView/_groupsListView.scss";
@import "../views/groups/groupEditView/_groupEditView.scss";

@import "../views/users/usersRootView/_usersRootView.scss";
@import "../views/users/usersListView/_usersListView.scss";
@import "../views/users/userEditView/_userEditView.scss";

@import "../components/_navigationMenu.scss";
@import "../components/fileUpload/_fileUpload.scss";

// @import "../components/datasetDetails/_datasetDetails.scss";
// @import "../components/datasetCreate/_datasetCreate.scss";

#root,
.App,
html,
body {
    margin: 0;
   
    height: 100%;
}

.App {
    display: flex;
}

#map {
    width: 100%;
    height: 100%;
}

@font-face {
    font-family: gotham;
    src: url(../fonts/GOTHAM-LIGHT.TTF);
}

@font-face {
    font-family: gotham-bold;
    src: url(../fonts/GOTHAM-BOLD.TTF);
}

.gotham {
    font-family: "gotham";
}

input {
    font-family: "gotham";
}

.gotham-bold {
    font-family: "gotham-bold";
}

.no-margin-progress {
    margin-bottom: -4px;
    z-index: 2;
}

.redux-toastr .toastr .rrt-left-container .rrt-holder {
    display: flex;
    align-items: center;

    svg {
        margin: 0 auto;
        display: block;
    }
}

.redux-toastr .toastr .rrt-middle-container {
    padding: 15px 5px;
}

.rrt-success {
    background-color: $primary-color !important;
}

.rrt-confirm-holder .rrt-confirm .rrt-message {
    font-size: 20px;
    color: $primary-text-color;
    background-color: $primary-background-color;
}

input:-internal-autofill-selected {
    background-color: transparent;
}

.map-container {
    width: 80%;
    display: flex;
    position: relative;

    &.full {
        width: 100%;
    }
}

.side-bar-container {
    width: 20%;
    display: flex;
    height: 100%;

    &.hide {
        width: 0% !important;
    }
}

.rc-slider {
    margin-top: 15px;

    .rc-slider-rail {
        background-color: $scondary-background-color;
    }

    .rc-slider-handle {
        background-color: white;
        border-color: white;
        box-shadow: 0 0 5px $primary-color;

        &:hover {
            border-color: $primary-color;
        }
    }

    .slider-footer {
        justify-content: space-between;
        align-items: center;
        display: flex;
    }

    .rc-slider-track {
        background-color: $primary-color;
    }
}

::-webkit-scrollbar-track {
    background-color: $primary-background-color;
}

::-webkit-scrollbar {
    width: 6px;
    background-color: $primary-background-color;
}

::-webkit-scrollbar-thumb {
    background-color: $scondary-background-color;
}

.MuiMenu-paper {
    background-color: $primary-background-color !important;
    color: white !important;
    .menu-button {
        .icon {
            font-size: 20px;

            padding-right: 16px;
        }
    }
}
