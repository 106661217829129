$primary-color:#008080;
$secondary-color:#1c1f24;
$tertiary-background-color:#181a1f;

$primary-background-color:#262a31 ;
$scondary-background-color:#1c1f24;

$primary-error-color:#b54c60;

$primary-element-color:#1c1f24;

$primary-text-color:white;
$secondary-text-color:whitesmoke;

$primary-icon-color:white;

$primary-color:#14567E;
$secondary-color:#094F79;
$tertiary-background-color:#b7b7b7;

$primary-background-color:#f1f0f0;
$scondary-background-color:white;

$primary-error-color:#b54c60;

$primary-element-color:white;

$primary-text-color:rgba(0,0,0,0.87);
$secondary-text-color:rgba(0, 0, 0, 0.6);

$primary-icon-color:rgba(0,0,0,0.87);

:export{
    primaryColor:$primary-color;
    primaryBackgroundColor:$primary-background-color;
    secondaryBackgroundColor:$scondary-background-color;
    primaryTextColor:$primary-text-color;
}


